.textResultLabel{
    font-size:14px;
    color:#383874;
    font-family: 'Roboto';
}

.textResult{
    margin-top: 4px;
    font-size:14px;
    color:#383874;
    font-weight: 400;
    opacity: 0.6;
}