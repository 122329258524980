.textResultLabel {
  font-size: 14px;
  color: #383874;
  font-weight: 400;
}

.textResult {
  margin-top: 4px;
  font-size: 14px;
  color: #383874;
  font-weight: 400;
  opacity: 0.6;
}

.resultColumn {
  overflow-y: scroll;
  // max-height: 700px;
}

.resultColumn::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  opacity: 0.5;
  margin-right: 20px;
  //background-color: #F5F5F5;
}

.resultColumn::-webkit-scrollbar {
  width: 5px;
  opacity: 0.5;
  //background-color: #F5F5F5;
}

.resultColumn::-webkit-scrollbar-thumb {
  border-radius: 10px;
  opacity: 0.5;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  //background-color: #555;
}
