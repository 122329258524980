.footer {
  position: fixed;
  margin-top: 100px;
  bottom: 0;
  left: 0 ;
  width: 100%;
  background-color: white;
  height : 40px;
  font-family: Roboto;
  letter-spacing: 1.5px;
}
