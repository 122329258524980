.navBar{
    transition: top 0.7s;
    position: sticky;
    right: 0;
    left: 0;
    top: 0;
    z-index: 20; 
    background-size: cover;
    height: 60px;
  }

.logo{
    width : 21px; 
    height : 21px;
}

.navText{
    color : #8484A0 ;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    margin-bottom: 0px;
    &:hover{
        color: #273B98;
    }
}

.iconSearch{
    right: 7px;
    top: 15%;
    bottom: 0;
    position: absolute;
}