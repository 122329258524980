.imageInput::-webkit-file-upload-button {
  visibility: hidden;
}

input::file-selector-button {
  margin: 0;
  visibility: hidden;
//   content: "check";
//   font-weight: bold;
//   color: dodgerblue;
//   padding: 0.5em;
//   border: thin solid grey;
//   border-radius: 3px;
//   width: 1px;
//   height: 3px;
}

// .imageInput::after {
//   visibility: hidden;
//   display: none;
// }

// .imageInput {
//   // background-color:#688AFF;
//   height: 44px;
//   border: 1px;
// }

.imageInput::before {
  height: 44px;
  color: white;
  text-transform: uppercase;
  content: url('../../../src/assets/icons/ic_upload.svg') " Tải lên hình ảnh";
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #688aff;
  border: 1px solid #3e62e0;
  border-radius: 6px;
  padding: 5px 8px;
  outline: none;
  //   white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  
}
.imageInput:hover::before {
//   border-color: black;
}
.imageInput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.layout{
  margin-bottom: 50px;
}


// input[type="file"]:after {
//   background-color: blue;
//   content: "";
//   display: block;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 11;
// }
